import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';
import Link from '@ckeditor/ckeditor5-link/src/link';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import EqnEditor5 from '@codecogs/eqneditor-ckeditor5/src/eqneditor5';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';

import {Bold, Italic, Code, Strikethrough, Subscript, Underline} from '@ckeditor/ckeditor5-basic-styles';
import {Indent, IndentBlock} from '@ckeditor/ckeditor5-indent';
import {FontSize, FontColor, FontBackgroundColor, FontFamily} from '@ckeditor/ckeditor5-font';
import {List, ListProperties, TodoList} from '@ckeditor/ckeditor5-list';
import {
	Image,
	ImageCaption,
	ImageInsert,
	ImageBlock,
	ImageInline,
	ImageStyle,
	ImageUpload,
	ImageResize,
	ImageToolbar,
	AutoImage,
    ImageTextAlternative
} from '@ckeditor/ckeditor5-image';
import {
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar
} from '@ckeditor/ckeditor5-table';

class ColdFusionUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('upload', file);

                fetch('/uploadImage', {
                    method: 'POST',
                    body: formData
                })
                .then(response => response.json())
                .then(result => {
                    if (result && result.url) {
                        resolve({ default: result.url });
                    } else {
                        reject(result.error || 'Erro ao enviar a imagem.');
                    }
                })
                .catch(error => reject(error));
            }));
    }

    abort() {
    }
}



export default class ClassicEditor extends ClassicEditorBase {}

function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new ColdFusionUploadAdapter(loader);
    };
}

ClassicEditor.builtinPlugins = [
    Essentials,
    Bold,
    Italic,
    Code, 
    Strikethrough, 
    Subscript, 
    Underline,
    Heading,
    Indent, 
	IndentBlock,
    FontSize, 
	FontColor, 
	FontBackgroundColor,
    List,
    ListProperties, 
    TodoList,
    Image,
	ImageInsert,
    MediaEmbed,
    EqnEditor5,
    Autoformat,
    AutoImage,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    FontFamily,
    Autosave,
    BlockQuote,
    FindAndReplace,
    Link,
    LinkImage,
    TextTransformation,
    PasteFromOffice, 
    Table, 
    TableCaption, 
    TableCellProperties, 
    TableColumnResize, 
    TableProperties, 
    TableToolbar,
    SpecialCharacters,
    ImageBlock,
    ImageInline,
    ImageTextAlternative
    

];

ClassicEditor.defaultConfig = {
    extraPlugins: [ CustomUploadAdapterPlugin ],
    toolbar: {
        items: [
            'findAndReplace',
            '|',
            'undo', 'redo',
            '|',
            'EqnEditor5',
            '|',
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'code',
            '|',
            'specialCharacters',
            'link',
            'insertImage',
            'mediaEmbed',
            'insertTable',
            'blockQuote',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            'outdent',
            'indent'
        ]
    },
	image: {
		toolbar: [
			'toggleImageCaption',
			'imageTextAlternative',
			'|',
			'imageStyle:inline',
			'imageStyle:wrapText',
			'imageStyle:breakText',
			'|',
			'resizeImage'
		]
	},
    language: 'pt-br'
};
